import * as React from "react";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import PricingSection from "../../sections/Pricing/PricingSection";
import Footer from "../../components/Footer";

const Pricing = () => {
  return (
    <>
      <Header />
      <PageLayout title="Pricing">
        <PricingSection />
        <Footer />
      </PageLayout>
    </>
  );
};

export default Pricing;
